.swipeable-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
}

.content {
  padding: 20px;
  background-color: #f8f8f8;
  transition: transform 0.3s ease;
}

.content.shift {
  transform: translateX(
    -100px
  ); /* Adjust this value for how far you want it to shift */
}

.action-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 86px;
}

.share-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 20px;
}

.delete-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 20px;
}
